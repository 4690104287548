<template>
	<!-- eslint-disable vue/no-multiple-template-root -->
	<button v-if="$store.state.authentication.tokenInfo.isAuthenticated" class="hover:text-goPinkDark active:text-goPinkDarker items-center bg-transparent border-0 cursor-pointer flex outline-0 whitespace-nowrap space-x-8" @click="myAccountButton_onClick">
		<svg-icon name="account-outline" class="iconSize-24" />
		<span>{{ $store.state.authentication.user.firstName }}</span>
	</button>
	<nuxt-link v-else to="/sign-in/" class="hover:text-goPinkDark active:text-goPinkDarker items-center bg-transparent border-0 cursor-pointer flex outline-0 whitespace-nowrap space-x-8">
		<svg-icon name="account-outline" class="iconSize-24" />
		<span>Sign in</span>
	</nuxt-link>
</template>

<script>
export default {
	methods: {
		myAccountButton_onClick() {
			this.$store.commit('toggleMyAccountModal')
			this.$store.dispatch('toggleMainMenu', false);
		}
	}
}
</script>
